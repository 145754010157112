/* eslint-disable no-dupe-keys */
import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { SearchParams } from "../types/appTypes";
import { Id, Report } from "../types/apiTypes";

export default class AdminAProjectReports extends BaseApi {
  public findMany(
    projectId: string,
    searchParams: SearchParams = {},
  ): Promise<Report[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}` : "";
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/projects/${projectId}/reports${query}`,
    });
  }

  findOne(id: Id): Promise<Report> {
    return doRequest(this._axiosInstance!, {
      method: "get",
      url: `/admin/reports/${id}`,
    });
  }

  createReport(projectId: string): Promise<Report> {
    return doRequest(this._axiosInstance!, {
      method: "post",
      url: `/admin/projects/${projectId}/reports`,
    });
  }

  updateReport(reportId: Id, data: Partial<Report>): Promise<Report> {
    return doRequest(this._axiosInstance!, {
      method: "put",
      url: `/admin/reports/${reportId}`,
      data,
    });
  }

  deleteReport(reportId: Id): Promise<Report> {
    return doRequest(this._axiosInstance!, {
      method: "delete",
      url: `/admin/reports/${reportId}`,
    });
  }

  //Mock function to replicate uploadPicture
  uploadPicture(file: File): Promise<string> {
    // return doRequest(this._axiosInstance!, {
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    //   method: "post",
    //   url: `/me/profile-picture`,
    //   data: formData,
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Image = reader.result as string;
        resolve(base64Image);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }
}
