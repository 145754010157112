import Actions from "@/components/common/Actions";
import DateValue from "@/components/common/DateValue";
import Page from "@/components/common/Page";
import StandardTable from "@/components/table/StandardTable";
import { useApi } from "@/context/AxiosContext";
import { useLoadResource } from "@/lib/request-hooks";
import { __r, ADMIN_PROJECT_REPORT } from "@/RouteMap";
import { Report } from "@/types/apiTypes";
import {
  Button,
  EmptyState,
  Pane,
  SearchIcon,
  Spinner,
  Table,
} from "evergreen-ui";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DeletetDialogComponent } from "./dialog/DeletetDialogComponent";

export function useClientReportList() {
  const [reports, setReports] = useState<Report[]>([]);
  const { apiInstance } = useApi();
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();

  const request = () =>
    apiInstance!.adminReports.findMany(projectId!, searchParams);

  function setData(data: { reports: Report[]; pages: number }) {
    setReports(data.reports);
  }

  return {
    ...useLoadResource(request, setData),
    reports,
  };
}

export default function ReportListPage() {
  const { reports, refresh, isLoading, meta, handle } = useClientReportList();
  const { projectId } = useParams();
  const { apiInstance } = useApi();
  const navigate = useNavigate();

  const [updatedReports, setUpdatedReports] = useState(reports);
  const [loaderVisibility, setLoaderVisibility] = useState(false);
  const [deleteLoaderVisibility, setDeleteLoaderVisibility] = useState(false);
  const [deleteDialogVisibility, setDeleteDialogVisibility] = useState(false);
  const [deleteReportIndex, setDeleteReportIndex] = useState(-1);

  const navTo = (reportId, projectId) => {
    navigate(
      __r(ADMIN_PROJECT_REPORT, {
        projectId,
        reportId,
      }),
    );
  };

  useEffect(() => {
    setUpdatedReports(reports);
  }, [reports]);

  function handleDeleteDialogOpen(index: number) {
    setDeleteReportIndex(index);
    setDeleteDialogVisibility(true);
  }

  function handleDeleteDialogClose() {
    setDeleteDialogVisibility(false);
  }

  async function handleDeleteReport() {
    setDeleteLoaderVisibility(true);
    const request = apiInstance!.adminReports.deleteReport(
      updatedReports[deleteReportIndex].id,
    );
    const response: { isDeleted: boolean } = await handle(request);
    if (response.isDeleted) {
      const finalReports = [...updatedReports];
      finalReports.splice(deleteReportIndex, 1);
      setUpdatedReports(finalReports);
    }
    setDeleteLoaderVisibility(false);
    setDeleteReportIndex(-1);
  }

  async function handleCreateReport() {
    setLoaderVisibility(true);
    const request = apiInstance!.adminReports.createReport(projectId!);
    const response: Report = await handle(request);
    setLoaderVisibility(false);
    navTo(response.id, response.projectId);
  }

  return (
    <Page>
      <Actions marginBottom={10}>
        <Actions.Button
          appearance="primary"
          onClick={handleCreateReport}
          className="new-report-spinner"
        >
          {loaderVisibility ? <Spinner size={15}></Spinner> : "New Report"}
        </Actions.Button>
      </Actions>
      <Pane>
        <StandardTable
          meta={meta}
          data={updatedReports}
          emptyState={<EmptyProjecReportState />}
          isLoading={isLoading}
          refresh={refresh}
        >
          <Table.Head paddingRight={0}>
            <Table.TextHeaderCell>Title</Table.TextHeaderCell>
            <Table.TextHeaderCell>Date</Table.TextHeaderCell>
            <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
          </Table.Head>
          <Table.Body height="auto">
            {updatedReports.map((report, index) => {
              return (
                <Table.Row
                  key={index}
                  isSelectable
                  onSelect={() => {
                    navTo(report.id, report.projectId);
                  }}
                >
                  <Table.TextCell>{report.title}</Table.TextCell>
                  <Table.TextCell>
                    <DateValue date={report.updatedAt} />
                  </Table.TextCell>
                  <Table.TextCell>
                    <Button
                      appearance="primary"
                      intent="danger"
                      minWidth={70}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteDialogOpen(index);
                      }}
                    >
                      Delete
                    </Button>
                  </Table.TextCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </StandardTable>
      </Pane>
      {deleteReportIndex > -1 && (
        <DeletetDialogComponent
          title="Delete report"
          bodyContent={`Are you sure you want to delete "${updatedReports[deleteReportIndex].title}" ?`}
          deleteDialogVisibility={deleteDialogVisibility}
          saveLoaderVisibility={deleteLoaderVisibility}
          handleCloseDeleteDialog={handleDeleteDialogClose}
          handleConfirmDeleteDialog={async () => {
            await handleDeleteReport();
            handleDeleteDialogClose();
          }}
        />
      )}
    </Page>
  );
}

function EmptyProjecReportState() {
  return (
    <EmptyState
      background="light"
      title="Nog geen Report toegevoegd"
      orientation="horizontal"
      icon={<SearchIcon color="#C1C4D6" />}
      iconBgColor="#EDEFF5"
      description="Activiteiten verschijnen als je op de knop 'Nieuwe Report' klikt. Daar kun je een nieuwe Report toevoegen."
    />
  );
}
