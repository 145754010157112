import { TextEditor } from "@/components/text-editor/TextEditor";
import { ReportComponent } from "@/types/apiTypes";
import { Dialog } from "evergreen-ui";
import { useState } from "react";
import { DialogFooter } from "./DialogFooter";

export function EditReportModuleDialogComponent({
  component,
  editorVisiblity,
  saveLoaderVisibility,
  onClose,
  onSave,
}: {
  component: ReportComponent;
  editorVisiblity: boolean;
  saveLoaderVisibility: boolean;
  onClose: () => void;
  onSave: (component: ReportComponent) => void;
}) {
  const [componentToBeUpdated, setComponentToBeUpdated] = useState(component);

  function handleCloseDialog() {
    setComponentToBeUpdated(component);
    onClose();
  }

  function handleConfirmDialog() {
    onSave(componentToBeUpdated);
  }

  return (
    <Dialog
      title="Edit Report"
      shouldCloseOnOverlayClick={false}
      width="90%"
      topOffset={25}
      shouldCloseOnEscapePress={false}
      isShown={editorVisiblity}
      shouldAutoFocus={true}
      onCloseComplete={handleCloseDialog}
      footer={
        <DialogFooter
          saveLoaderVisibility={saveLoaderVisibility}
          handleCloseDialog={handleCloseDialog}
          handleConfirmDialog={handleConfirmDialog}
        />
      }
    >
      <GetComponent
        component={componentToBeUpdated}
        handleComponentUpdate={(component) =>
          setComponentToBeUpdated(component)
        }
      />
    </Dialog>
  );
}

function GetComponent({
  component,
  handleComponentUpdate,
}: {
  component: ReportComponent;
  handleComponentUpdate: (component: ReportComponent) => void;
}) {
  switch (component.type) {
    case "textEditor":
      return (
        <TextEditor
          component={component}
          handleComponentUpdate={handleComponentUpdate}
        />
      );
  }
}
