import {
  AddReportPosition,
  ReportComponent,
  ReportComponentIds,
  TableComponentColumns,
  TableComponentData,
  TableType,
} from "@/types/apiTypes";
import { ReportModuleComponent } from "./ReportModuleComponent";

export function ReportModuleComponents({
  components,
  saveLoaderVisibility,
  handleComponentChanges,
}: {
  components: ReportComponent[];
  saveLoaderVisibility: boolean;
  handleComponentChanges: (components: ReportComponent[]) => Promise<void>;
}) {
  async function handleComponentUpdate(
    component: ReportComponent,
    index: number,
  ) {
    const componentsAfterUpdate = [...components];
    componentsAfterUpdate[index] = component;
    await handleComponentChanges(componentsAfterUpdate);
  }

  async function handleComponentDelete(index: number) {
    const componentsAfterUpdate = [...components];
    componentsAfterUpdate.splice(index, 1);
    await handleComponentChanges(componentsAfterUpdate);
  }

  async function handleAddModule(
    index: number,
    position: AddReportPosition,
    componentType: ReportComponentIds,
    tableType?: TableType,
  ) {
    const finalIndex = position === "after" ? index + 1 : index;
    const componentsAfterUpdate = [...components];
    let component: ReportComponent | null = null;
    switch (componentType) {
      case "textEditor":
        component = {
          type: "textEditor",
          content: "<p>Sample Report template</p>",
        };
        break;

      case "pageBreak":
        component = {
          type: "pageBreak",
        };
        break;

      case "table":
        component = {
          type: "table",
          columns: getColumnsBasedOnTableType(tableType!),
          data: getDataBasedOnTableType(tableType!),
        };
        break;
    }

    componentsAfterUpdate.splice(finalIndex, 0, component!);
    await handleComponentChanges(componentsAfterUpdate);
  }
  return components.map((component, index) => (
    <ReportModuleComponent
      key={index}
      component={component}
      index={index}
      isLastElement={components.length === 1 && index === components.length - 1}
      saveLoaderVisibility={saveLoaderVisibility}
      handleComponentUpdate={handleComponentUpdate}
      handleComponentDelete={handleComponentDelete}
      handleAddModule={handleAddModule}
    />
  ));
}

function getColumnsBasedOnTableType(
  tableType: TableType,
): TableComponentColumns[] {
  if (tableType === "observations") {
    return [
      {
        key: "date",
        label: "Datum",
        formatter: "date",
      },
      {
        key: "time",
        label: "Tijd",
        formatter: "time",
      },
      {
        key: "temperature",
        label: "Temperatuur",
        formatter: "string",
      },
      {
        key: "precipitation",
        label: "Neerslag en bewolking",
        formatter: "string",
      },
      {
        key: "wind",
        label: "Wind",
        formatter: "string",
      },
    ];
  } else if (tableType === "species") {
    return [
      {
        key: "species",
        label: "Species",
        formatter: "string",
      },
      {
        key: "observation",
        label: "Observatie",
        formatter: "time",
      },
      {
        key: "location",
        label: "Locatie",
        formatter: "string",
      },
      {
        key: "animalCout",
        label: "Aantal dieren",
        formatter: "number",
      },
      {
        key: "observationDate",
        label: "Datum observatie",
        formatter: "date",
      },
      {
        key: "observationMedia",
        label: "Informatie en mediae",
        formatter: "string",
      },
    ];
  }

  return [];
}

function getDataBasedOnTableType(tableType: TableType): TableComponentData[] {
  if (tableType === "observations") {
    return [
      {
        date: "2024-08-27 15:06:55.653",
        time: "2024-08-27 15:06:55.653",
        temperature: "26 deg celsius",
        precipitation: "[dryest precipitation], 10% bewolkt",
        wind: "[lowest wind force] Bft South-West",
      },
      {
        date: "2024-08-29 15:06:55.653",
        time: "2024-08-29 15:06:55.653",
        temperature: "10 deg celsius",
        precipitation: "[dryest precipitation], 50% bewolkt",
        wind: "[lowest wind force] Bft North",
      },
    ];
  } else if (tableType === "species") {
    return [
      {
        species: "Serotine bat",
        observation: "summer roost",
        location: "Koperslager 36, terrein, boom]",
        animalCout: 4,
        observationDate: "2024-08-27 15:06:55.653",
        observationMedia: "[observed behaviour] Appendix",
      },
      {
        species: "Serotine bat",
        observation: "Common swift",
        location: "nest location",
        animalCout: 4,
        observationDate: "2024-08-27 15:06:55.653",
        observationMedia: "[observed behaviour] Appendix",
      },
    ];
  }

  return [];
}
