import { TableComponent } from "@/types/apiTypes";
import { Table } from "evergreen-ui";
import { getFormattedData } from "../modules/utility";

export const TablePreviewComponent = ({
  component,
}: {
  component: TableComponent;
}) => {
  const { columns, data } = component;
  return (
    <Table className="max-h-36 overflow-hidden">
      <Table.Head>
        {columns.map((column, index) => (
          <Table.TextHeaderCell key={index}>
            {column.label}
          </Table.TextHeaderCell>
        ))}
      </Table.Head>
      <Table.Body height={240}>
        {data.map((data, index) => (
          <Table.Row key={index}>
            {columns.map((column, colIndex) => (
              <Table.TextCell key={colIndex}>
                {getFormattedData(data[column.key], column.formatter)}
              </Table.TextCell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
