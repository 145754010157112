import { Image, Text, View } from "@react-pdf/renderer";
import { stylesForPdf } from "./styles/pdfReportStyle";

export const ReportPdfHeader = ({ reportTitle }: { reportTitle: string }) => {
  return (
    <View style={stylesForPdf.header} fixed>
      <Text style={stylesForPdf.reportTitle}>{reportTitle}</Text>
      <Image style={stylesForPdf.logo} src={"/logo.png"} />
    </View>
  );
};
