import { Button, Spinner } from "evergreen-ui";

export function DialogFooter({
  saveLoaderVisibility,
  handleCloseDialog,
  handleConfirmDialog,
}: {
  saveLoaderVisibility: boolean;
  handleCloseDialog: () => void;
  handleConfirmDialog: () => void;
}) {
  return (
    <div>
      <Button appearance="default" onClick={handleCloseDialog}>
        Cancel
      </Button>
      <Button
        marginLeft={16}
        appearance="primary"
        onClick={handleConfirmDialog}
      >
        {saveLoaderVisibility ? (
          <Spinner size={20} className="white-spinner"></Spinner>
        ) : (
          "Confirm"
        )}
      </Button>
    </div>
  );
}
