import { HtmlStyles } from "react-pdf-html";

export const stylesForPdfHtml: HtmlStyles = {
  body: {
    color: "#333333",
  },
  h1: {
    fontSize: "26px",
    fontWeight: 700,
    margin: "16px 0",
  },
  h2: {
    fontSize: "19.5px",
    fontWeight: 700,
    margin: "16px 0",
  },
  p: {
    fontSize: "13px",
    margin: "8px 0",
  },
};
