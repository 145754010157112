import { ReportComponent } from "@/types/apiTypes";
import { TextEditorPreviewComponent } from "./TextEditorPreviewComponent";
import { PageBreakPreviewComponent } from "./PageBreakPreviewComponent";
import { TablePreviewComponent } from "./TablePreviewComponent";

export const ReportModulePreviewComponent = ({
  component,
}: {
  component: ReportComponent;
}) => {
  const { type } = component;
  switch (type) {
    case "textEditor":
      return <TextEditorPreviewComponent content={component.content} />;
    case "pageBreak":
      return <PageBreakPreviewComponent />;
    case "table":
      return <TablePreviewComponent component={component} />;
  }
};
