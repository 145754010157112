import { Dialog } from "evergreen-ui";
import { DialogFooter } from "./DialogFooter";

export function DeletetDialogComponent({
  title,
  bodyContent,
  deleteDialogVisibility,
  handleCloseDeleteDialog,
  handleConfirmDeleteDialog,
  saveLoaderVisibility,
}: {
  title: string;
  bodyContent: string;
  deleteDialogVisibility: boolean;
  saveLoaderVisibility: boolean;
  handleCloseDeleteDialog: () => void;
  handleConfirmDeleteDialog: () => void;
}) {
  return (
    <Dialog
      isShown={deleteDialogVisibility}
      title={title}
      onCloseComplete={handleCloseDeleteDialog}
      footer={
        <DialogFooter
          saveLoaderVisibility={saveLoaderVisibility}
          handleCloseDialog={handleCloseDeleteDialog}
          handleConfirmDialog={handleConfirmDeleteDialog}
        />
      }
    >
      {bodyContent}
    </Dialog>
  );
}
