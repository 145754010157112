import { AddIcon, DeleteIcon, EditIcon } from "evergreen-ui";
import { EditReportModuleDialogComponent } from "../dialog/EditReportModuleDialogComponent";
import { ReportModulePreviewComponent } from "../preview-components/ReportModulePreviewComponent";
import { useState } from "react";
import {
  AddReportPosition,
  ReportComponentIds,
  ReportComponent,
  TableType,
} from "@/types/apiTypes";
import { AddReportModuleDialogComponent } from "../dialog/AddReportModuleDialogComponent";
import { DeletetDialogComponent } from "../dialog/DeletetDialogComponent";

export const ReportModuleComponent = ({
  component,
  index,
  saveLoaderVisibility,
  isLastElement,
  handleComponentUpdate,
  handleComponentDelete,
  handleAddModule,
}: {
  component: ReportComponent;
  index: number;
  saveLoaderVisibility: boolean;
  isLastElement: boolean;
  handleComponentUpdate: (
    component: ReportComponent,
    index: number,
  ) => void;
  handleComponentDelete: (index) => void;
  handleAddModule: (
    index: number,
    position: AddReportPosition,
    componentType: ReportComponentIds,
    tableTypeValue?: TableType,
  ) => void;
}) => {
  const [editorVisiblity, setEditorVisiblity] = useState(false);
  const [deleteDialogVisibility, setDeleteDialogVisibility] = useState(false);
  const [addReportModuleDialogVisiblity, setAddReportModuleDialogVisiblity] =
    useState(false);

  function handleEditDialogOpen() {
    setEditorVisiblity(true);
  }

  function handleEditDialogClose() {
    setEditorVisiblity(false);
  }

  function handleDeleteDialogOpen() {
    setDeleteDialogVisibility(true);
  }

  function handleDeleteDialogClose() {
    setDeleteDialogVisibility(false);
  }

  function handleAddReportModuleDialogOpen() {
    setAddReportModuleDialogVisiblity(true);
  }
  function handleAddReportModuleDialogClose() {
    setAddReportModuleDialogVisiblity(false);
  }

  return (
    <div className="card-container mt-4 border border-zinc-200 bg-white px-6 pt-4 pb-6 drop-shadow-sm">
      <div className="flex justify-between pb-3">
        <div className="flex justify-start">
          <AddIcon
            color="#3366ff"
            cursor="pointer"
            onClick={handleAddReportModuleDialogOpen}
          />
        </div>
        <div className="flex justify-end">
          {component.type !== "pageBreak" && (
            <EditIcon
              onClick={handleEditDialogOpen}
              marginRight={16}
              cursor="pointer"
            />
          )}
          {!isLastElement && (
            <DeleteIcon
              onClick={handleDeleteDialogOpen}
              color="#ff6565"
              cursor="pointer"
            />
          )}
        </div>
      </div>
      <ReportModulePreviewComponent component={component} />

      {addReportModuleDialogVisiblity && (
        <AddReportModuleDialogComponent
          addReportModuleDialogVisibility={addReportModuleDialogVisiblity}
          saveLoaderVisibility={saveLoaderVisibility}
          handleOnClose={handleAddReportModuleDialogClose}
          handleOnSave={async (position, componentType, tableType?) => {
            await handleAddModule(index, position, componentType, tableType);
            handleAddReportModuleDialogClose();
          }}
        />
      )}

      {editorVisiblity && (
        <EditReportModuleDialogComponent
          component={component}
          editorVisiblity={editorVisiblity}
          saveLoaderVisibility={saveLoaderVisibility}
          onClose={handleEditDialogClose}
          onSave={async (component) => {
            await handleComponentUpdate(component, index);
            handleEditDialogClose();
          }}
        />
      )}
      {deleteDialogVisibility && (
        <DeletetDialogComponent
          title="Delete module"
          bodyContent="Are you sure you want to delete the module?"
          deleteDialogVisibility={deleteDialogVisibility}
          saveLoaderVisibility={saveLoaderVisibility}
          handleCloseDeleteDialog={handleDeleteDialogClose}
          handleConfirmDeleteDialog={async () => {
            await handleComponentDelete(index);
            handleDeleteDialogClose();
          }}
        />
      )}
    </div>
  );
};
