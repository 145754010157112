import { TableComponentDataType, TableFornatterType } from "@/types/apiTypes";

export function getFormattedData(
  data: TableComponentDataType,
  formatter: TableFornatterType,
) {
  switch (formatter) {
    case "date":
      return new Date(data).toDateString();

    case "time":
      return new Date(data).toTimeString();

    case "number":
      return +data;

    case "string":
      return `${data}`;
  }
}
