import {
  AddReportPosition,
  ReportComponentIds,
  TableType,
} from "@/types/apiTypes";
import { Dialog, RadioGroup } from "evergreen-ui";
import { useState } from "react";
import { DialogFooter } from "./DialogFooter";

export function AddReportModuleDialogComponent({
  addReportModuleDialogVisibility,
  saveLoaderVisibility,
  handleOnClose,
  handleOnSave,
}: {
  addReportModuleDialogVisibility: boolean;
  saveLoaderVisibility: boolean;
  handleOnClose: () => void;
  handleOnSave: (
    position: AddReportPosition,
    componentType: ReportComponentIds,
    tableTypeValue?: TableType,
  ) => void;
}) {
  const positionOptions = [
    { label: "Before", value: "before" },
    { label: "After", value: "after" },
  ];

  const componentTypeOptions = [
    { label: "Text Editor", value: "textEditor" },
    { label: "Table", value: "table" },
    { label: "Page Break", value: "pageBreak" },
  ];

  const tableTypeOptions = [
    { label: "Observations", value: "observations" },
    { label: "Species", value: "species" },
  ];

  const [positionValue, setPositionValue] =
    useState<AddReportPosition>("before");
  const [componentTypeValue, setComponentTypeValue] =
    useState<ReportComponentIds>("textEditor");
  const [tableTypeValue, setTableTypeValue] =
    useState<TableType>("observations");

  return (
    <Dialog
      isShown={addReportModuleDialogVisibility}
      title="Add Module"
      onCloseComplete={handleOnClose}
      footer={
        <DialogFooter
          saveLoaderVisibility={saveLoaderVisibility}
          handleCloseDialog={handleOnClose}
          handleConfirmDialog={() =>
            handleOnSave(positionValue, componentTypeValue, tableTypeValue)
          }
        />
      }
    >
      <div className="flex">
        <RadioGroup
          label="Position"
          size={16}
          value={positionValue}
          options={positionOptions}
          onChange={(event) =>
            setPositionValue(event.target.value as AddReportPosition)
          }
        />
        <RadioGroup
          marginLeft={72}
          label="Module Type"
          size={16}
          value={componentTypeValue}
          options={componentTypeOptions}
          onChange={(event) =>
            setComponentTypeValue(event.target.value as ReportComponentIds)
          }
        />
        {componentTypeValue === "table" && (
          <RadioGroup
            marginLeft={72}
            label="Table Type"
            size={16}
            value={tableTypeValue}
            options={tableTypeOptions}
            onChange={(event) =>
              setTableTypeValue(event.target.value as TableType)
            }
          />
        )}
      </div>
    </Dialog>
  );
}
