import Html from "react-pdf-html";
import { stylesForPdfHtml } from "./styles/pdfReportContentStyle";
import { TextEditorComponent } from "@/types/apiTypes";

export const PdfPreviewTextComponent = ({
  component,
}: {
  component: TextEditorComponent;
}) => {
  const htmlContent = `<html><body>${component.content}</body></html>`;
  return <Html stylesheet={stylesForPdfHtml}>{htmlContent}</Html>;
};
