import { TableComponent } from "@/types/apiTypes";
import { Text, View } from "@react-pdf/renderer";
import { stylesForPdf } from "./styles/pdfReportStyle";
import { getFormattedData } from "../modules/utility";

export const PdfPreviewTableComponent = ({
  component,
}: {
  component: TableComponent;
}) => {
  const { columns, data } = component;

  function getCSSForFirstAndLastRow(rowIndex: number) {
    const styles: { [key: string]: string | number }[] = [];

    if (rowIndex !== data.length - 1 && rowIndex === 0) {
      styles.push({
        borderTop: "1px solid #333333",
        marginTop: -1,
      });
    }
    if (rowIndex !== 0 && rowIndex === data.length - 1) {
      styles.push({
        borderTop: "1px solid #333333",
        marginTop: -1,
      });
    }

    return styles;
  }
  return (
    <>
      <View style={[stylesForPdf.tableHeaderRow]}>
        {columns.map((column, index) => (
          <Text
            key={index}
            style={[
              stylesForPdf.tableData,
              stylesForPdf["tableData::last-child"],
              {
                width: `${100 / columns.length}%`,
              },
            ]}
          >
            {column.label}
          </Text>
        ))}
      </View>

      <View style={{ display: "flex", flexDirection: "column" }}>
        {data.map((row, rowIndex) => (
          <View
            key={rowIndex}
            style={[
              stylesForPdf.tableBodyRow,
              ...getCSSForFirstAndLastRow(rowIndex),
            ]}
          >
            {columns.map((column, colIndex) => (
              <Text
                key={colIndex}
                style={[
                  stylesForPdf.tableData,
                  stylesForPdf["tableData::last-child"],
                  {
                    width: `${100 / columns.length}%`,
                  },
                ]}
                break
              >
                {getFormattedData(row[column.key], column.formatter)}
              </Text>
            ))}
          </View>
        ))}
      </View>
    </>
  );
};
