import { useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "@/context/AxiosContext";
import { useLoadResource } from "@/lib/request-hooks";
import { Report, ReportComponent } from "@/types/apiTypes";
import { Paragraph, Spinner, TextInput, toaster } from "evergreen-ui";
import { PdfPreviewDocument } from "./pdf/PdfPreviewDocument";
import { ReportModuleComponents } from "./modules/ReportModuleComponents";

export function useClientReport() {
  const { reportId } = useParams();
  const { apiInstance } = useApi();

  const [reportData, setReportData] = useState<Report>();

  const request = () => apiInstance!.adminReports.findOne(reportId!);

  return {
    ...useLoadResource(request, setReportData),
    reportData,
  };
}

export default function ReportEditPage() {
  const { reportData } = useClientReport();

  if (reportData) {
    return <ReportContainer report={reportData} />;
  }

  return (
    <div className="absolute left-1/2	top-1/2	 flex items-center">
      <Spinner size={50} />
      <Paragraph marginLeft={12}>Loading Report...</Paragraph>
    </div>
  );
}

const ReportContainer = ({ report }: { report: Report }) => {
  const [reportToBeUpdated, setReportToBeUpdated] = useState(report);
  const { components, title, id } = reportToBeUpdated;

  const [saveLoaderVisibility, setSaveLoaderVisibility] = useState(false);
  const [reportTitle, setReportTitle] = useState(title);

  const { handle } = useClientReport();
  const { apiInstance } = useApi();

  async function handleReportSave(updatedComponents: ReportComponent[]) {
    setSaveLoaderVisibility(true);
    const request = apiInstance!.adminReports.updateReport(id, {
      title: reportTitle,
      components: updatedComponents,
    });
    const response = await handle(request);
    toaster.success("Report saved successfully!");
    setSaveLoaderVisibility(false);
    setReportToBeUpdated(response);
  }

  if (components.length) {
    return (
      <div className="h-full overflow-x-scroll pb-8">
        <ReportEditorHeaderSection
          reportTitle={reportTitle}
          handleReportTitleChange={(value) => setReportTitle(value)}
          saveLoaderVisibility={saveLoaderVisibility}
          handleReportSave={() => handleReportSave(components)}
        />
        <div className="flex justify-between">
          <div className="shrink-0 grow-0 basis-3/6 overflow-x-scroll pr-4">
            <ReportModuleComponents
              components={components}
              saveLoaderVisibility={saveLoaderVisibility}
              handleComponentChanges={handleReportSave}
            />
          </div>
          <ReportPreview report={reportToBeUpdated} />
        </div>
      </div>
    );
  }
};

const ReportEditorHeaderSection = ({
  reportTitle,
  saveLoaderVisibility,
  handleReportTitleChange,
  handleReportSave,
}: {
  reportTitle: string;
  saveLoaderVisibility: boolean;
  handleReportTitleChange: (value: string) => void;
  handleReportSave: () => void;
}) => {
  return (
    <div className="mt-4 mb-8 flex items-center">
      <TextInput
        defaultValue={reportTitle}
        name="reportTitle"
        placeholder="Report Title"
        onChange={(e) => handleReportTitleChange(e.target.value)}
        onBlur={handleReportSave}
      />
      {saveLoaderVisibility && (
        <Spinner size={25} marginLeft={10} className="blue-spinner"></Spinner>
      )}
    </div>
  );
};

const ReportPreview = ({ report }: { report: Report }) => {
  return (
    <div className="shrink-0 grow-0 basis-3/6">
      <PdfPreviewDocument report={report} />
    </div>
  );
};
