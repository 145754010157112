import { Page, Document, PDFViewer, View } from "@react-pdf/renderer";
import { Report, ReportComponent } from "@/types/apiTypes";
import { stylesForPdf } from "./styles/pdfReportStyle";
import { PdfPreviewTextComponent } from "./PdfPreviewTextComponent";
import { PdfPreviewTableComponent } from "./PdfPreviewTableComponent";
import { ReportPdfHeader } from "./PdfHeader";
import ReportPdfFooter from "./PdfFooter";

export const PdfPreviewDocument = ({ report }: { report: Report }) => {
  const { components, title } = report;
  return (
    <PDFViewer showToolbar={true} className="h-full w-full overflow-hidden">
      <Document>
        <Page size="A4" dpi={100} style={stylesForPdf.page}>
          <ReportPdfHeader reportTitle={title} />
          {components.map((component, index) => {
            if (component.type === "pageBreak") {
              return <View break key={index}></View>;
            }
            return (
              <View style={stylesForPdf.content} key={index}>
                <CreateReportContents component={component} />
              </View>
            );
          })}
          <ReportPdfFooter />
        </Page>
      </Document>
    </PDFViewer>
  );
};

const CreateReportContents = ({
  component,
}: {
  component: ReportComponent;
}) => {
  switch (component.type) {
    case "textEditor":
      return <PdfPreviewTextComponent component={component} />;
    case "table":
      return <PdfPreviewTableComponent component={component} />;
  }
};
