import { StyleSheet } from "@react-pdf/renderer";

export const stylesForPdf = StyleSheet.create({
  page: {
    paddingTop: 115,
    paddingBottom: 80,
    height: "80%",
  },
  content: {
    paddingHorizontal: 78,
  },
  header: {
    position: "absolute",
    top: 0,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 78,
    paddingRight: 20,
    paddingTop: 10,
  },
  logo: {
    width: 150,
  },
  reportTitle: {
    fontSize: 14,
  },
  footer: {
    position: "absolute",
    height: 80,
    width: "100%",
    backgroundColor: "#77cb00",
    bottom: 0,
  },
  tableHeaderRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "13px",
    border: "1px solid #333333",
    borderRight: 0,
  },
  tableData: {
    borderRight: "1px solid #333333",
    padding: 8,
    borderBottom: 0,
  },

  tableBodyRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "13px",
    borderLeft: "1px solid #333333",
    borderBottom: "1px solid #333333",
    borderRight: 0,
  },
});
