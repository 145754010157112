import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";
import { useState } from "react";
import { TextEditorComponent } from "@/types/apiTypes";

export const TextEditor = ({
  component,
  handleComponentUpdate,
}: {
  component: TextEditorComponent;
  handleComponentUpdate: (component: TextEditorComponent) => void;
}) => {
  const [value, setValue] = useState(component.content);
  function handleChange(content) {
    setValue(content);
    handleComponentUpdate({
      type: "textEditor",
      content,
    });
  }
  return (
    <SunEditor
      autoFocus={true}
      lang="en"
      setOptions={{
        showPathLabel: false,
        minHeight: "60vh",
        maxHeight: "60vh",
        placeholder: "Enter your text here!!!",
        plugins: [
          align,
          font,
          fontColor,
          fontSize,
          formatBlock,
          hiliteColor,
          horizontalRule,
          lineHeight,
          list,
          paragraphStyle,
          table,
          template,
          textStyle,
          image,
          link,
        ],
        buttonList: [
          ["formatBlock"],
          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          ["list", "align", "outdent", "indent", "link", "image"],
        ],
        formats: ["p", "h1", "h2"],
      }}
      defaultValue={value}
      onChange={handleChange}
    />
  );
};
